import React from "react";
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Badge,
  useMediaQuery,
  useTheme,
  Tooltip,
  SvgIcon,
  styled,
  Box,
} from "@mui/material";
import { SupportIcon } from "../icons";

import poweredByIcon from "../../static/images/logo-sequel.png";
import { useSideBarTabs } from "../../providers/SideBarTabsProvider";
import Drawer from "./Drawer";
import { Tab } from "./useCommonController";
import { useConfigValue } from "@src/providers/config";
import { PostMessageEvent, useEmitEvent } from "@src/providers/embed";
interface Props {
  /**
   * Flag to show the powered by Introvoke logo
   */
  showPoweredBy: boolean;

  /**
   * Flag that enables audience view
   */
  showAudienceView?: boolean;

  /**
   * Flag that shows the live support icon
   */
  showLiveSupport?: boolean;
}

const TabBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -8,
    top: -4,
    backgroundColor:
      theme.customTheme?.chat?.badge?.backgroundColor ||
      theme.palette.primary.main,
    color:
      theme.customTheme?.chat?.badge?.textColor || theme.palette.common.white,
  },
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .MuiTabs-scroller": {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const TabItem = styled(MuiTab)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  width: "56px",
  minWidth: "56px",
  minHeight: "44px",
  opacity: 1,
  color: theme.palette.base[400],
  padding: 0,
  overflow: "visible",
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.primary.main}40`,
    color: theme.palette.primary.main,
  },
  "& .MuiTab-iconWrapper": {
    fontWeight: 600,
    textTransform: "none",
  },
}));

// Simple flag that renders tab content when it isn't the current tab. False would render null
const USE_HIDDEN_TABS = true;

const TabPanel = React.memo(function TabPanel(
  props: React.HTMLAttributes<HTMLDivElement> & {
    tabKey: string;
    currentTabKey: string | null;
    children: React.ReactNode;
  },
) {
  const { tabKey, currentTabKey, children } = props;

  return (
    <Box
      role="tabpanel"
      hidden={currentTabKey !== tabKey}
      sx={{
        display: currentTabKey !== tabKey ? "none" : "flex",
        flex: 1,
      }}
    >
      {USE_HIDDEN_TABS || tabKey === currentTabKey ? children : null}
    </Box>
  );
});

const Container = styled("div")(({ theme: { breakpoints, palette } }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  backgroundColor: palette.common.white,
}));

const TabBar = styled("div")(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    overflow: "visible",
    borderColor: palette.divider,
    [breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      position: "sticky", // fixes icons being stuck when collapsing on mobile
    },
    [breakpoints.up("md")]: {
      borderLeftStyle: "solid",
      borderLeftWidth: 1,
    },
  }),
);

const EndContentContainer = styled("div")(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderColor: palette.divider,
    marginLeft: spacing(1),
    marginRight: spacing(1),
    [breakpoints.down("md")]: {
      flexDirection: "row",
    },
  }),
);

const SupportIconSvg = styled(SvgIcon)(({ theme: { palette } }) => ({
  color: palette.base[400],
  "&:hover": {
    color: palette.primary.main,
  },
}));

const EventSideBar = (props: Props) => {
  const { showPoweredBy, showAudienceView, showLiveSupport } = props;

  const { breakpoints } = useTheme();
  const config = useConfigValue();
  const isLargeScreen = useMediaQuery(breakpoints.up("md"), { noSsr: true }); // Need noSsr to be the correct value on first render
  const [drawerVisible, setDrawerVisible] = React.useState(
    isLargeScreen || config.hybridMode,
  );

  useEmitEvent(
    {
      event: PostMessageEvent.DRAWER_CHANGED,
      data: { opened: drawerVisible },
    },
    [drawerVisible],
  );

  const {
    currentTab: currentTabKey,
    setCurrentTab: setCurrentTabKey,
    tabs,
  } = useSideBarTabs();

  const currentTab = React.useMemo(
    () =>
      !currentTabKey
        ? null
        : (tabs as Tab[]).find(({ key }) => key === currentTabKey),
    [currentTabKey, tabs],
  );

  return (
    <>
      <Container
        sx={({ breakpoints }) => ({
          [breakpoints.down("md")]: {
            bottom: 0,
            position: "sticky",
            flexDirection: "column-reverse", // tab bar on top
            justifyContent: config?.hybridMode ? "flex-end" : "flex-start",
            height: config?.hybridMode ? "100%" : undefined,
          },
        })}
      >
        <Drawer
          visible={drawerVisible}
          header={currentTab?.label}
          showPoweredBy={showPoweredBy}
          showAudienceView={showAudienceView}
          onClose={() => {
            setDrawerVisible(false);
            setCurrentTabKey(null);
          }}
        >
          {tabs?.map(({ key, content }) => (
            <TabPanel key={key} tabKey={key} currentTabKey={currentTabKey}>
              {content}
            </TabPanel>
          ))}
        </Drawer>

        <TabBar>
          <Tabs
            orientation={isLargeScreen ? "vertical" : "horizontal"}
            value={currentTabKey === null ? false : currentTabKey}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            onChange={(_event, value) => {
              if (!drawerVisible) {
                // Open drawer
                setDrawerVisible(true);
              }
              setCurrentTabKey(value);
            }}
          >
            {tabs?.map(
              ({ key, label, icon, badgeCount, badgeCountCompact }) => (
                <TabItem
                  key={key}
                  value={key}
                  data-testid={`sidebar-tab-${key}-btn`}
                  icon={
                    !badgeCount ? (
                      icon
                    ) : (
                      <TabBadge
                        badgeContent={
                          <Tooltip
                            title={badgeCount.toLocaleString()}
                            data-testid={`sidebar-tab-${key}-count`}
                          >
                            <span>{badgeCountCompact}</span>
                          </Tooltip>
                        }
                        color="primary"
                        max={999}
                      >
                        {icon}
                      </TabBadge>
                    )
                  }
                  aria-label={label}
                />
              ),
            )}
          </Tabs>

          <EndContentContainer>
            {showLiveSupport && (
              <Box sx={{ padding: 1 }}>
                <Tooltip title="Sequel offers 24/7 Live Event support to business customers. To get in contact with an on-call team member, please call: +1 (628) 267-3666">
                  <a
                    href="tel:+1-628-267-3666"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SupportIconSvg>
                      <SupportIcon />
                    </SupportIconSvg>
                  </a>
                </Tooltip>
              </Box>
            )}

            {showPoweredBy && (
              <Box
                sx={{
                  flexShrink: 0,
                  width: "48px",
                  height: "30px",
                  padding: 1,
                }}
              >
                <a
                  href="https://www.sequel.io/virtual-stage-landing-page?utm_source=powered-by&utm_medium=chat-logo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    component="img"
                    src={poweredByIcon}
                    alt="Sequel"
                    width="100%"
                    height="100%"
                  />
                </a>
              </Box>
            )}
          </EndContentContainer>
        </TabBar>
      </Container>
    </>
  );
};

export default React.memo(EventSideBar);
