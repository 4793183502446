import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLatest } from "react-use";
import { BoxProps } from "@mui/material";

import { VideoType } from "@src/models/eventType";
import { useEvent } from "@src/providers/EventProvider";
import FeatureLockedDialog from "@src/components/dialogs/FeatureLockedDialog";
import VideoPlayer from "@src/components/VideoPlayer/VideoPlayer";
import {
  VideoPlayerCallbacks,
  VideoPlayerInstance,
} from "@src/components/VideoPlayer/types";
import LiveReactions from "@src/components/LiveReactions";
import useLiveClosedCaptions from "@src/components/VideoPlayer/hooks/useLiveClosedCaptions";
import {
  useClosedCaptionsFeatureEnabled,
  useClosedCaptionsSubscription,
} from "@src/providers/closedCaptions";
import { useUpdateLengthOfWatch } from "../EventStage/useUpdateLengthOfWatch";

import { Container, MutedAlert } from "./EventVideoPlayer.styles";

import { logEvent } from "@introvoke/react/metrics";
import { MetricsEvents } from "@src/metrics";
import { PostMessageEvent, useEmitEvent } from "@src/providers/embed";
import { UserRole } from "@src/contracts/user/user";

interface Props extends Pick<BoxProps, "style" | "className" | "sx"> {
  /**
   * The event video source
   */
  src?: string;

  /**
   * Set to `true` to loop the video
   */
  loop?: boolean;

  /**
   * The type of video to display (livestream or on-demand replay)
   */
  videoType: VideoType;

  /**
   * Callback invoked when the playing state changes
   */
  onPlayingStateChange?: (playing: boolean) => void;

  /**
   * Callback invoked when the video ends (livestream stopped or replay reaches end)
   */
  onEnded?: () => void;

  /**
   * Callback invoked when an error occurs attempting to play the media
   */
  onError?: (error: MediaError) => void;
}

interface UseHandleLiveCaptionsArgs {
  src: string | undefined;
  videoPlayerRef: MutableRefObject<VideoPlayerInstance | undefined>;
  videoType: VideoType;
}

const useHandleLiveCaptions = ({
  src,
  videoPlayerRef,
  videoType,
}: UseHandleLiveCaptionsArgs) => {
  const liveCaptionsEnabled = useClosedCaptionsFeatureEnabled();
  const [captionsState, setCaptionsState] = useState<{
    isActive: boolean;
    language: string | null;
  }>({
    isActive: false,
    language: null,
  });

  const liveCaptionsDisabled =
    !liveCaptionsEnabled ||
    !src ||
    !videoPlayerRef.current ||
    videoType !== VideoType.Live;

  const captions = useClosedCaptionsSubscription({
    disabled: liveCaptionsDisabled || !captionsState.isActive,
    language: captionsState.language,
  });

  const onLanguageChange = useCallback((lang: string | undefined) => {
    const isActive = !!lang;
    if (isActive) {
      logEvent(MetricsEvents.VirtualStage.CC_ENABLED);
    }
    setCaptionsState({
      isActive,
      language: lang ?? null,
    });
  }, []);

  useLiveClosedCaptions({
    supportedLanguages: ["en"],
    captions,
    disabled: liveCaptionsDisabled,
    playerRef: videoPlayerRef,
    onLanguageChange,
  });
};

/**
 * Video player component used for playing either the event
 * livestream or an on-demand replay if available.
 *
 * _NOTE_: Only a single instance of this component should be rendered
 */
export function EventVideoPlayer({
  src,
  videoType,
  onPlayingStateChange,
  onError,
  onEnded,
  loop,
  ...boxProps
}: Props) {
  const [showMuted, setShowMuted] = useState(false);
  const videoPlayerRef = useRef<VideoPlayerInstance>();
  const { data: event } = useEvent();
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);

  const latestOnPlayingStateChange = useLatest(onPlayingStateChange);
  const latestOnError = useLatest(onError);
  const latestOnEnded = useLatest(onEnded);

  const handleUpdateLengthOfWatch = useUpdateLengthOfWatch(videoType);

  useEffect(() => {
    latestOnPlayingStateChange.current?.(isVideoPlaying);
  }, [isVideoPlaying, latestOnPlayingStateChange]);

  useEmitEvent(
    {
      event: videoEnded
        ? PostMessageEvent.EVENT_ENDED
        : PostMessageEvent.EVENT_STARTED,
      data: {
        role: UserRole.Viewer,
        endedAfterStartTime: event ? new Date() > event?.startDate : undefined,
      },
    },
    [videoEnded],
  );

  useHandleLiveCaptions({
    src,
    videoPlayerRef,
    videoType,
  });

  const handleUnmute = useCallback(() => {
    videoPlayerRef.current?.muted(false);
    setShowMuted(false);
  }, []);

  const handleError = useCallback(
    (err: MediaError, instance: VideoPlayerInstance) => {
      setVideoPlaying(false);
      latestOnError.current?.(err);
    },
    [latestOnError],
  );

  const handleEnded = useCallback(() => {
    setVideoPlaying(false);
    setVideoEnded(true);
    latestOnEnded.current?.();
    if (loop) {
      try {
        videoPlayerRef.current?.currentTime(0);
        videoPlayerRef.current?.play();
      } catch (e) {
        // ignore
      }
    }
  }, [latestOnEnded, loop]);

  const handlePause = useCallback(() => {
    setVideoPlaying(false);
  }, []);

  const handlePlay = useCallback(() => {
    setVideoPlaying(true);
  }, []);

  const handleReady = useCallback((instance: VideoPlayerInstance) => {
    videoPlayerRef.current = instance;
  }, []);

  const handleOnDestroy = useCallback(() => {
    videoPlayerRef.current = undefined;
  }, []);

  const handleVolumeChange: VideoPlayerCallbacks["onVolumeChange"] = (
    event,
    player,
    volume,
    muted,
  ) => {
    setShowMuted(muted);
  };

  return (
    <Container data-testid="event-video-player-container" {...boxProps}>
      {showMuted && (
        <MutedAlert
          data-testid="event-video-player-muted-alert"
          onClick={handleUnmute}
          severity="warning"
        >
          The video is muted. Press here to unmute.
        </MutedAlert>
      )}
      {videoType === VideoType.OnDemand && event?.playbackLocked ? (
        <FeatureLockedDialog
          data-testid="event-video-player-feature-lock"
          open
          title="Event Playback Disabled"
          hideAction
        />
      ) : (
        <>
          <VideoPlayer
            src={src}
            containerProps={{
              style: {
                display: "flex",
                justifyContent: "center",
              },
            }}
            videoJsOptions={{
              inactivityTimeout: 2000,
            }}
            autoplay
            muted={false}
            loop={videoType !== VideoType.Live}
            controls={videoType !== VideoType.Upcoming}
            onReady={handleReady}
            onVolumeChange={handleVolumeChange}
            onPlay={handlePlay}
            onPause={handlePause}
            onError={handleError}
            onEnded={handleEnded}
            onDestroy={handleOnDestroy}
            onWatchInterval={handleUpdateLengthOfWatch}
          />
          <LiveReactions
            hideToolbar={false}
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </>
      )}
    </Container>
  );
}

export default React.memo(EventVideoPlayer);
