import React, { memo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 as uuidV4 } from "uuid";

import {
  useEvent,
  useHasEventEnded,
  useBlockedEmails,
} from "../../providers/EventProvider";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useUser, useSetUserRole } from "../../providers/UserProvider";
import { useDialog } from "@src/providers/DialogProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useConfigValue } from "@src/providers/config";

import { QueryKeys } from "../../api/QueryKeys";
import { Api } from "../../api/api";

import { saveUserRole, saveGeneralAccessCookie } from "../../helpers/cookie";
import { getUserBaseAvatar } from "../../helpers/getUserBaseAvatar";
import { validateEnvironment } from "../../helpers/validateEnvironment";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { CodeInput, useValidateCode } from "../Forms/CodeInput";
import ProfileImageUpload from "../Forms/ProfileImageUpload";
import { TextInputLiveFeedback } from "../Forms/TextInputLiveFeedback";
import { TabTitle } from "../TabTitle";
import { BaseButton } from "../buttons/BaseButton";
import PasscodeToggleButtons from "../buttons/PasscodeToggleButtons";
import ConnectionErrors, {
  connectionErrorOpenOptions,
} from "../dialogs/content/ConnectionErrors";

import { Registration, RegistrationType } from "../../contracts/event/event";
import { UserRole, UserUpdate } from "../../contracts/user/user";
import { isEmailFromBusinessDomain } from "@src/helpers/email";
import { useShowPoweredBy } from "@src/providers/customization";
import poweredByImage from "@src/static/images/powered-by-sequel.jpg";

const addAuthKeyToUrl = (
  key: string | undefined,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (typeof key === "string") {
    // update url
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("authKey", key as string);
    navigate({
      search: urlParams.toString(),
    });
  } else {
    console.warn(`invalid user auth key received`);
  }
};

export interface RegistrationSideBarProps {
  eventId?: string;
}

/**
 * Renders the sidebar for those events/hubs which do not have registration enabled.
 *
 * This handles the following:
 * - No registration enabled (email + name required or name-only required)
 * - Legacy events/hubs which have access pass codes enabled
 *
 * _NOTE_: Please be aware that pass codes enabled is currently indicated by the property `isRegistrationModeEnabled`
 * on events.
 */
export const NoRegistrationSideBar = memo(
  ({ eventId }: RegistrationSideBarProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { data: event } = useEvent();
    const { data: networkingHub } = useNetworkingHub();
    const isNetworkingHubPage = !!networkingHub;
    const blockedEmails = useBlockedEmails();
    const showPoweredBy = useShowPoweredBy();
    const navigate = useNavigate();
    const { openDialog } = useDialog();
    const enableAdminRegistrationV2 = useFeatureFlag(
      FeatureFlag.ADMIN_REGISTRATION_V2,
    );

    const uid = event?.uid || networkingHub?.uid;

    const registrationType = networkingHub
      ? RegistrationType.NETWORKING_HUB
      : RegistrationType.EVENT;

    const registration: Registration = {
      // prioritize event
      ...(networkingHub?.registration || {}),
      ...(event?.registration || {}),
    };

    const { isRegistrationModeEnabled, isAttendeeRegistrationModeEnabled } = {
      // prioritize event
      isAttendeeRegistrationModeEnabled: !!(
        event?.isAttendeeRegistrationModeEnabled ||
        networkingHub?.isAttendeeRegistrationModeEnabled
      ),
      isRegistrationModeEnabled: !!(
        event?.isRegistrationModeEnabled ||
        networkingHub?.isRegistrationModeEnabled
      ),
    };

    const isInNoRegistrationMode =
      !isRegistrationModeEnabled &&
      !registration?.enabled &&
      !registration?.outsideOfAppEnabled;

    const setUserRole = useSetUserRole();
    const user = useUser();
    const cacheClient = useQueryClient();
    const { clearValidatedRole, validateCode, validatedUserRole } =
      useValidateCode(registrationType);

    const { meeting, nameOnlyEntry } = useConfigValue();

    const eventHasEnded = useHasEventEnded();

    /**
     * hide the email input only when registration v2 is enabled and the event is not using access codes
     * or nameOnlyEntry is enabled
     */
    const hideEmailInput =
      (enableAdminRegistrationV2 &&
        !isRegistrationModeEnabled &&
        registration?.isEmailInputEnabled === false) ||
      nameOnlyEntry;

    // TODO: switch to react-hook-form and zod
    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        code: "",
        showCodeInput: isAttendeeRegistrationModeEnabled || false,
        hideEmailInput: hideEmailInput || false,
        firstName: "",
        lastName: "",
        ...user,
      },
      validationSchema: Yup.object({
        firstName: Yup.string().trim().required("First name is required"),
        lastName: Yup.string().trim().required("Last name is required"),
        email: Yup.string().when(
          "hideEmailInput",
          (hideEmailInput: boolean, schema: any) => {
            return hideEmailInput
              ? Yup.string().nullable().notRequired()
              : Yup.string()
                  .email("Please enter a valid email address")
                  .required("Email is required")
                  .test({
                    name: "allowOnlyBusinessEmails",
                    test: (email) =>
                      registration.blockedEmails?.allowOnlyBusinessEmails
                        ? isEmailFromBusinessDomain(email)
                        : true,
                    message: "Please use a business email address",
                  });
          },
        ),
        showCodeInput: Yup.boolean(),
        code: Yup.string().when(
          "showCodeInput",
          (showCodeInput: boolean, schema: any) => {
            return showCodeInput
              ? Yup.string().test(
                  "code",
                  "${message}", // eslint-disable-line no-template-curly-in-string
                  async (value, testContext) =>
                    validateCode(testContext, uid, value),
                )
              : schema.min(0);
          },
        ),
      }),
      onSubmit: async (values) => {
        setErrorMessage("");
        setLoading(true);

        if (blockedEmails?.includes?.(values.email.toLowerCase())) {
          setErrorMessage("You have been blocked from the event");
          setLoading(false);
          return;
        }

        // check if user's environment satisfies requirements
        const { hasErrors, errors } = await validateEnvironment(
          validatedUserRole,
        );

        if (hasErrors) {
          setLoading(false);
          openDialog(
            "ConnectionErrors",
            <ConnectionErrors errors={errors} />,
            connectionErrorOpenOptions,
          );
          return;
        }

        if (hideEmailInput && !values.email) {
          // create a dummy email
          values.email = `${uuidV4()}@annon.user`;
        }

        const name = `${values.firstName.trim()} ${values.lastName.trim()}`;

        const userUpdate: UserUpdate = {
          uid: `${user.uid}|||${values.email}`,
          originalId: user.uid,
          name,
          email: values.email.trim(),
          profilePicture:
            values.profilePicture || getUserBaseAvatar(user.uid, name),
          updated: true,
          eventId: event?.uid ? event.uid : undefined,
          networkingHubId: networkingHub?.uid ? networkingHub.uid : undefined,
        };

        try {
          const result = await Api.UserApi.createUpdate(userUpdate);
          cacheClient.setQueryData(QueryKeys.user(user.uid), result);

          // save to cookie
          if (uid && result?.authToken) {
            setUserRole(validatedUserRole);
            saveUserRole(validatedUserRole, uid as string, result.authToken);

            if (event?.uid) {
              // save general access cookie with the event's companyId for multisession events
              saveGeneralAccessCookie({
                accessId: event.organizerUid,
                authKey: result.authToken,
                userRole: UserRole.Viewer,
              });
            }

            clearValidatedRole();
            setUserRole(validatedUserRole);
          }
          // save key to url
          addAuthKeyToUrl(result.authToken, navigate);
        } catch (err) {
          cacheClient.invalidateQueries(QueryKeys.user(user.uid));
          setErrorMessage(
            "Error creating user, check your internet connection and try again.",
          );
        }
        setLoading(false);
      },
    });

    const { errors, isValidating, isSubmitting, touched, values } = form;
    const emailError = errors.email;
    const nameError = errors.firstName || errors.lastName;
    const codeError = touched.code
      ? values.showCodeInput && errors.code
      : values.showCodeInput;
    const submissionDisabled =
      !form.dirty ||
      isValidating ||
      isSubmitting ||
      !!emailError ||
      !!nameError ||
      !!codeError;

    if (!isNetworkingHubPage) {
      // show loading indicator while event is loading
      if (event === undefined || event === null) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        );
      }
    }

    // Required for v1 registration events with passcodes
    if (!isRegistrationModeEnabled && !enableAdminRegistrationV2) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h1">Invalid credentials</Typography>
        </Box>
      );
    }

    const loadingIndicator = loading ? (
      <div
        style={{
          position: "relative",
          left: "-20px",
          display: "inline-flex",
          top: ".25em",
        }}
      >
        <CircularProgress color="secondary" size={20} />
      </div>
    ) : null;

    let submitButtonText = loading
      ? "Joining"
      : meeting
      ? `Join Meeting`
      : eventHasEnded
      ? "Watch now"
      : `Join Session`;
    if (validatedUserRole === UserRole.Organizer) {
      submitButtonText += ` as Host`;
    } else if (validatedUserRole === UserRole.Presenter) {
      submitButtonText += ` as Presenter`;
    }

    const title = meeting
      ? "Add your info below to join the meeting"
      : isNetworkingHubPage
      ? "Ready to join the networking session?"
      : eventHasEnded
      ? "Register to watch this event"
      : "Ready to join the event?";

    return (
      <FormikProvider value={form}>
        <Form className="flex h-full w-full flex-col items-center justify-start overflow-hidden overflow-y-auto bg-white p-6">
          <TabTitle compact={true}>{title}</TabTitle>
          <ProfileImageUpload fieldId="profilePicture" iconButtonOnly />

          <div className="flex w-full gap-4">
            <TextInputLiveFeedback
              label="First Name"
              id="name"
              name="firstName"
              testId="first-name-input"
              placeholder="John"
            />
            <TextInputLiveFeedback
              label="Last Name"
              id="lastName"
              name="lastName"
              testId="last-name-input"
              placeholder="Doe"
            />
          </div>

          {!hideEmailInput && (
            <TextInputLiveFeedback
              label="Email Address"
              id="email"
              name="email"
              testId="email-input"
              placeholder="johndoe@email.com"
            />
          )}
          {!isInNoRegistrationMode &&
            (isAttendeeRegistrationModeEnabled ? (
              <CodeInput
                name="code"
                id="code"
                label="Type your 6-digit passcode here:"
                type={registrationType}
              />
            ) : (
              !meeting &&
              !hideEmailInput && (
                <PasscodeToggleButtons
                  disabledOptionLabel={`No passcode`}
                  enabledOptionLabel={`I have a passcode`}
                  enabledContent={
                    <CodeInput
                      name="code"
                      id="code"
                      label="Type your 6-digit passcode here:"
                      type={registrationType}
                    />
                  }
                  fieldId={"showCodeInput"}
                />
              )
            ))}

          {errorMessage ? (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          ) : null}

          <BaseButton
            type="submit"
            data-testid="join-btn"
            variant="contained"
            color="primary"
            disabled={submissionDisabled}
            sx={{
              marginBottom: "24px",
              borderRadius: "40px",
              color: "white",
              padding: "1em",
              width: "80%",
              marginTop:
                enableAdminRegistrationV2 ||
                hideEmailInput ||
                meeting ||
                isNetworkingHubPage
                  ? "24px"
                  : "",
            }}
            onClick={() => form.handleSubmit}
          >
            <>
              {loadingIndicator}
              {submitButtonText}
            </>
          </BaseButton>
          {showPoweredBy && (
            <a
              className="mx-auto mt-auto flex h-[14px] w-[130px] justify-center bg-white"
              href="https://www.sequel.io/virtual-stage-landing-page?utm_source=powered-by&utm_medium=chat-logo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={poweredByImage}
                alt="Powered by Sequel"
                className="h-full w-full"
              />
            </a>
          )}
        </Form>
      </FormikProvider>
    );
  },
);
