import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { Api } from "../api/api";
import { QueryKeys } from "../api/QueryKeys";
import { extractIdsFromUrl } from "../helpers/utils";
import { useUser } from "./UserProvider";
import { Environment, useIsEnvironment } from "./EnvironmentProvider";
import ErrorPage from "@src/components/ErrorPage";

export enum FeatureFlag {
  /**
   * Feature flag that enables pinned chat messages
   */
  PINNED_CHAT_MESSAGES = "pinned-chat-messages",
  /**
   * Feature flag that enables approve QnA
   */
  APPROVE_QNA = "approve-qna",
  /**
   * A sample feature flag used for demo purposes only
   */
  SAMPLE_FEATURE_FLAG = "sample-feature-flag",
  /**
   * Enables lower thirds in the Virtual Stage
   */
  VS_LOWER_THIRDS = "vs-lower-thirds",
  /**
   * Enables the Daily iframe in networking hub
   */
  NH_USE_DAILY_IFRAME = "nh-use-daily-iframe",
  /**
   * Enables knocking for meetings in networking hub
   */
  NH_ENABLE_DAILY_KNOCKING = "nh-enable-daily-knocking",
  /**
   * Enables the new media queue in the Virtual Stage
   */
  VS_MEDIA_QUEUE = "vs-media-queue",
  /**
   * Enables the going on stage countdown in the Virtual Stage
   */
  VS_MOVE_ONSTAGE_COUNTDOWN = "vs-move-onstage-countdown",
  /**
   * Disabled checking browsers before joining virtual stage
   */
  VS_DISABLE_BROWSER_CHECK = "vs-disable-browser-check",
  /**
   * Enables the use of the new circle stage UI (vs ui component)
   */
  HUB_CIRCLE_STAGE_V2 = "hub-circle-stage-v2",
  /**
   * Enables Q&A and polls within networking hubs
   */
  HUB_QA_AND_POLLS = "hub-qa-and-polls",
  /**
   * Enables enforcing plan features/limits
   */
  EVENT_ENFORCE_LIMITS = "event-enforce-limits",
  /**
   * Enables GraphQL subscription for the EventStatus
   */
  EVENT_STATUS_SUBSCRIPTION = "event-status-subscription",
  /**
   * Enables the new `EventOnboardingDialog` experience for hosts and presenters
   */
  EVENT_ONBOARDING_DIALOG = "event-onboarding-dialog",
  /**
   * Enables the chat server v2 implementation backed by GraphQL
   */
  CHAT_SERVER_V2 = "embed-chat-server-v2",
  /**
   * Enables chat reactions in the app (applies to both events and Networking Hubs)
   */
  CHAT_REACTIONS = "chat-reactions",
  /**
   * Enables the event's live reactions
   */
  EVENT_LIVE_REACTIONS = "live-reactions",
  /**
   * Enables the closed captioning on an event
   */
  EVENT_CLOSED_CAPTIONS = "event-close-captions",
  /**
   * Enables the use of our own live-streaming service (capturer)
   */
  EVENT_OWN_STREAMING = "event-own-streaming",
  /**
   * Enabled the use of our circle recording service (capturer)
   */
  CIRCLE_RECORDINGS = "circle-recordings",
  /**
   * Enabled the use of our circle mingling. (we are removing this feature incrementally)
   */
  CIRCLE_MINGLE = "enabled-circle-mingle",
  /**
   * Enables v2 admin registration
   */
  ADMIN_REGISTRATION_V2 = "admin-registration-v2",
  /**
   * Enables the new registration redesign on embed
   */
  EMBED_REGISTRATION_V2 = "embed-registration-v2",
  /**
   * enables the countdown refactor
   */
  COUNTDOWN_REFACTOR = "embed-countdown-refactor",
  /**
   * enables device step for host and presenter before entering stage
   */
  ENABLE_DEVICE_SETUP_STEP = "embed-device-setup-step",
  /**
   * Hides the sidebar when an event ends
   */
  ENABLE_HIDE_SIDEBAR_POST_EVENT = "embed-hide-post-event-sidebar",
  /**
   * Enabled routing refactor work
   */
  ENABLE_ROUTING_REFACTOR = "embed-routing-refactor",
  /**
   * Disable the cookies role and rely only on API
   */
  DISABLE_COOKIE_ROLE = "embed-disable-cookie-role",
  /**
   * Disable the cookies role and rely only on API
   */
  USE_ORIGINAL_ID_PUBNUB = "use-original-id-pubnub",
  /**
   * Enables new telemetry endpoint
   */
  ENABLE_NEW_TELEMETRY_ENDPOINT = "new-telemetry-endpoint",
  /**
   * Enables the full screen toggle on embed to include the chat component
   */
  FULL_SCREEN_TOGGLE_ON_EMBED = "full-screen-toggle-on-embed",
  /**
   * Turns on for OEM customers
   */
  IS_OEM_CUSTOMER = "is-oem-customer",
}

const featureFlagsAtom = atom<Record<FeatureFlag, boolean | undefined>>({
  key: "featureFlagsAtom",
  default: {} as Record<FeatureFlag, undefined>,
});

interface Props {
  fallback?: React.ReactNode;
}

export const FeatureFlagsProvider = (props: React.PropsWithChildren<Props>) => {
  const user = useUser();
  const isTestEnvironment = useIsEnvironment(Environment.TEST);

  const setFeatureFlags = useSetRecoilState(featureFlagsAtom);

  const options = extractIdsFromUrl();

  const { data, isLoading, isError, error } = useQuery<
    Record<FeatureFlag, boolean | undefined>,
    Error
  >(
    QueryKeys.featureFlags({
      userId: user?.uid || "",
      email: user?.email,
      userRole: user?.userRole,
      ...options,
    }),
    async () => {
      try {
        const flags = await Api.FeatureFlagsApi.GetFeatureFlags({
          flags: Object.values(FeatureFlag),
          ...options,
        });

        setFeatureFlags(flags);
        return flags;
      } catch (err) {
        // need to manually throw specific error again since react-query throws a default error with the status only
        // alternatively, we can create an [error, setError] state variable and use the `onError` to `setError(err.message)`
        if ((err as Error).message === "Event not found") {
          throw new Error((err as Error).message);
        }
        return {};
      }
    },
    {
      retry: !isTestEnvironment ? 2 : false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      enabled: !!user?.uid,
    },
  );

  useEffect(() => data && setFeatureFlags(data), [data, setFeatureFlags]);

  if (isError) {
    return <ErrorPage message={error.message} showRefresh={false} />;
  }

  if (!data || isLoading) {
    return <>{props.fallback ?? null}</>;
  }

  return <>{props.children}</>;
};

/**
 * Hook to get if a feature flag is enabled or disabled
 *
 * @param flag The flag to retrieve
 * @returns A boolean value indicating if the flag is enabled.
 *          May return `undefined` if not initialized
 */
export const useFeatureFlag = (flag: FeatureFlag) => {
  return useRecoilValue(featureFlagsAtom)[flag];
};

export const __testable__ = {
  featureFlagsAtom,
};
