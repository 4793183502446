import React, { useMemo } from "react";
import type { Control, UseFormSetValue } from "react-hook-form";

import Select from "@src/components/Forms/Select";
import TextField from "@src/components/Forms/TextField";

import {
  type RegistrationQuestion,
  RegistrationQuestionAnswerType,
  MAX_TEXT_LONG_LENGTH,
} from "@src/contracts/event/event";
import Checkbox from "@src/components/Forms/Checkbox";
import { sanitizeHTML } from "@src/helpers/utils";

export interface CustomQuestionProps {
  index: number;
  question: RegistrationQuestion;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const CustomQuestion = ({ index, question, control }: CustomQuestionProps) => {
  const name = `customQuestions.${index}`;

  const sanitizedLabel = useMemo(() => {
    const sanitized = sanitizeHTML(question.question) ?? "invalid question";
    return `${sanitized}${question.isRequired ? " *" : ""}`;
  }, [question.isRequired, question.question]);

  const label = <p dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />;

  if (
    question.type === RegistrationQuestionAnswerType.SELECT &&
    Array.isArray(question.options)
  ) {
    return (
      <Select
        id={question.id}
        data-testid={question.id}
        key={question.id}
        label={label}
        options={question.options ?? []}
        name={name}
        control={control}
        sx={{ mt: 2 }}
      />
    );
  }

  // TODO: come back to this when we add support for multiple answers
  // if (
  //   question.type === RegistrationQuestionAnswerType.MULTIPLE_ANSWER &&
  //   Array.isArray(question.options)
  // ) {
  //   return (
  //     <MultipleAnswer
  //       options={question.options}
  //       label={label}
  //       control={control}
  //       name={name}
  //       setValue={setValue}
  //     />
  //   );
  // }

  if (question.type === RegistrationQuestionAnswerType.CHECKBOX) {
    return (
      <Checkbox
        id={question.id}
        data-testid={question.id}
        key={question.id}
        label={label}
        name={name}
        control={control}
      />
    );
  }

  // Default to text box
  return (
    <TextField
      id={question.id}
      data-testid={question.id}
      key={question.id}
      control={control}
      label={label}
      name={name}
      placeholder="Enter your answer"
      showCharacterCount
      maxLength={
        question.type === RegistrationQuestionAnswerType.TEXT_LONG
          ? MAX_TEXT_LONG_LENGTH
          : undefined
      }
      LabelProps={{ sx: { mt: 2 } }}
    />
  );
};

export default React.memo(CustomQuestion);
