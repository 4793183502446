/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";

import { usePrevious } from "react-use";
import Box from "@mui/material/Box";

import { UserRole } from "../../../contracts/user/user";
import { useEvent } from "../../../providers/EventProvider";
import {
  EventScheduledTimelineStatus,
  useEventScheduledTimelineStatus,
  useEventState,
  useEventVideoType,
} from "../../../providers/EventStateProvider";
import { useUser } from "../../../providers/UserProvider";
import PromotedConfirmation from "../../dialogs/content/PromotedConfirmation";
import { VideoType } from "@src/models/eventType";
import { useDialog } from "@src/providers/DialogProvider";
import { EventVideoPlayer } from "@src/components/Event/EventVideoPlayer";
import { useVideoSource } from "./useVideoSource";
import { useUpdateVideoView } from "./useUpdateVideoView";

import BadBrowserAlert from "@src/components/Event/EventStage/BadBrowserAlert";
import Header from "@src/components/Event/EventStage/Header/Header";
import StageHeader from "@src/components/Event/EventStage/Header/StageHeader";
import { addCloudinaryImageUrlParams } from "@src/helpers/image";
import { useResizeObserver } from "@react-hookz/web";
import { roundUpToNearest } from "@src/helpers/numbers";
import { IconButton } from "@introvoke/react/components/IconButton";
import { Stack, Typography } from "@mui/material";
import CloseIcon from "@introvoke/react/icons/X";

export interface Props {
  name: string;
  eventName: string;
  profilePicture: string;
  eventPicture: string;
  userRole: UserRole;
  startDate: Date;
  onEnterStage?: () => void;
}

const DEFAULT_IMAGE_URL =
  "https://res.cloudinary.com/introvoke/image/upload/v1682107617/zyzc23vjrjscrrcgl3w9.png";

const ViewerLayoutContent = () => {
  const { openDialog } = useDialog();
  const { userRole } = useUser();
  const {
    setPromoteStatus,
    promoteStatus,
    enterStage,
    isEventLive,
    replayEnabled,
  } = useEventState();
  const [startingSoon, setStartingSoon] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const delayedScheduledStatus = useEventScheduledTimelineStatus();
  const [showSimuliveBanner, setShowSimuliveBanner] = useState(
    delayedScheduledStatus !== EventScheduledTimelineStatus.AFTER,
  );
  const [isStreamLive, setIsStreamLive] = useState(isEventLive);
  const { data: event } = useEvent();
  const isSimuliveEvent = !!event?.simuliveMediaId || !!event?.simuliveUrl;

  invariant(event, "Event is required");

  let videoType = useEventVideoType();
  if (isStreamLive) {
    videoType = VideoType.Live;
  }

  const handleUpdateVideoView = useUpdateVideoView(videoType);

  const { src, reset, isLoading } = useVideoSource({
    videoType,
    disabled: userRole === UserRole.Unregistered,
  });

  const previousSource = usePrevious(src);
  const hasNewVideoView =
    src &&
    previousSource !== src &&
    videoType !== VideoType.Upcoming &&
    userRole !== UserRole.Unregistered;
  useEffect(() => {
    if (hasNewVideoView) {
      handleUpdateVideoView();
    }
  }, [handleUpdateVideoView, hasNewVideoView]);

  useEffect(() => {
    setStartingSoon(isLoading);
  }, [isLoading]);

  // Fixes issue where livestream ends too soon
  // we internally need to change the type after the stream stops playing
  // as the flag can end before the stream actually ends
  const lastIsEventLive = usePrevious(isEventLive);
  useEffect(() => {
    if (lastIsEventLive && !isEventLive && isVideoPlaying) {
      // When we switch from live to not live, make sure we wait for the live video feed to end
      return;
    }

    setIsStreamLive(isEventLive);
  }, [isEventLive, isVideoPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If the event is currently live, or they've enabled replays, show the video player and stage
   */
  const showReplay = replayEnabled || !!event.streamInfo.videoLoopUrl;
  const showLive = isStreamLive || isEventLive;

  const showStageContents =
    userRole !== UserRole.Unregistered && // Unregistered users can't view live event stage contents
    !startingSoon && // verifies streaming URL is working
    (showLive || showReplay); // show replays (when enabled)

  useEffect(() => {
    if (promoteStatus === "promoted") {
      openDialog(
        "PromotedConfirmation",
        <PromotedConfirmation
          role={UserRole.Presenter}
          onConfirm={() => setPromoteStatus("accepted")}
          onCancel={() => setPromoteStatus("canceled")}
        />,
        {
          maxWidth: "xs",
          priority: "high",
          onClose: () => setPromoteStatus("canceled"),
        },
      );
    }
  }, [openDialog, promoteStatus, setPromoteStatus]);

  const imageUrl = useMemo(() => {
    return addCloudinaryImageUrlParams(
      event?.picture || DEFAULT_IMAGE_URL,
      `c_limit,w_${roundUpToNearest(window.innerWidth, 50)}`,
    );
  }, [event?.picture]);

  const imageContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(imageContainerRef, () => {
    const container = imageContainerRef.current;

    if (!container) return;

    if (container.clientHeight < 100 && container.style.opacity !== "0") {
      container.style.opacity = "0";
    }
    if (container.clientHeight >= 100 && container.style.opacity !== "1") {
      container.style.opacity = "1";
    }
  });

  return (
    <>
      <Box
        sx={{
          zIndex: 0,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          filter: "blur(16px)",
        }}
      />
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
          flex: 1,
          minHeight: 0,
          minWidth: 0,
        }}
      >
        <BadBrowserAlert />

        {isSimuliveEvent &&
          (userRole === UserRole.Presenter ||
            userRole === UserRole.Organizer) &&
          showSimuliveBanner && (
            <Box
              sx={{
                backgroundColor: "#F6F7F9",
                padding: 1,
              }}
            >
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    borderRadius: "8px",
                    background: "#FFFBEA",
                    padding: "16px",
                  }}
                >
                  <img
                    src="/svg/play.svg"
                    alt="Play icon"
                    width="24"
                    height="24"
                  />
                </Box>
                <Stack direction="column" spacing={1} flexGrow={1}>
                  <Typography variant="h3">
                    {isEventLive
                      ? "Simulive is running"
                      : "This is a Simulive event"}
                  </Typography>
                  <Typography variant="body1">
                    {isEventLive
                      ? "The event will end automatically once the content is finished"
                      : "The event will automatically begin at the scheduled start time"}
                  </Typography>
                </Stack>
                <Stack direction="column" sx={{ minWidth: "100px" }}>
                  <Typography variant="caption">
                    👀 only visible to you
                  </Typography>
                  <IconButton
                    style={{ alignSelf: "flex-end" }}
                    icon={CloseIcon}
                    aria-label="close banner"
                    onClick={() => setShowSimuliveBanner(false)}
                  />
                </Stack>
              </Stack>
            </Box>
          )}
        {showStageContents ? (
          <>
            <StageHeader
              isStreamLive={isEventLive}
              onEnterStage={
                userRole === UserRole.Presenter ||
                userRole === UserRole.Organizer
                  ? enterStage
                  : undefined
              }
            />
            <EventVideoPlayer
              src={src}
              loop={videoType === VideoType.OnDemand}
              videoType={videoType}
              sx={{ display: showStageContents ? "flex" : "none" }}
              onPlayingStateChange={setIsVideoPlaying}
              onError={() => reset()}
            />
          </>
        ) : (
          <>
            <Header
              startingSoon={startingSoon}
              isStreamLive={isStreamLive}
              onEnterStage={
                userRole === UserRole.Presenter ||
                userRole === UserRole.Organizer
                  ? enterStage
                  : undefined
              }
            />
            <Box
              ref={imageContainerRef}
              sx={{
                flex: 1,
                backgroundImage: `url(${imageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                transition: "opacity 0.2s",
              }}
            ></Box>
          </>
        )}
      </Box>
    </>
  );
};

export const ViewerLayout = () => {
  return <ViewerLayoutContent />;
};
